<template>
  <div>
    <v-row justify="center">
      <v-col cols="12">
        <v-card
          class="rounded-xl"
          :color="template.login.block"
          :light="!template.isDark(template.login.block)"
          :dark="template.isDark(template.login.block)"
        >
          <v-row
            justify="center"
            class="mt-5 pt-5"
            v-if="
              publicdata.verein || $route.path == '/profil-vervollstaendigen'
            "
          >
            <v-col cols="11">
              <h2 class="font-weight-light" v-if="user.displayName">
                Willkommen,
                <br />
                <span class="font-weight-bold">{{ user.displayName }}</span>
              </h2>
            </v-col>
            <v-col cols="11">
              <h2 class="my-4 font-weight-light text-center">
                Bitte vervollständige dein Profil:
              </h2>
            </v-col>
          </v-row>

          <v-form ref="form" v-model="valid" lazy-validation>
            <v-row justify="center">
              <v-col cols="11" md="5" class="py-0">
                <v-text-field
                  filled
                  rounded
                  label="Vorname"
                  :rules="[rules.required, rules.name]"
                  v-model="z_daten.vorname"
                ></v-text-field>
              </v-col>
              <v-col cols="11" md="5" class="py-0">
                <v-text-field
                  filled
                  rounded
                  label="Nachname"
                  :rules="[rules.required, rules.name]"
                  v-model="z_daten.name"
                ></v-text-field>
              </v-col>
              <v-col cols="11" md="10" class="py-0">
                <v-text-field
                  filled
                  rounded
                  label="Adresse"
                  :rules="[rules.required, rules.street, rules.streetnr]"
                  v-model="z_daten.adresse"
                ></v-text-field>
              </v-col>
              <v-col cols="11" md="4" class="py-0">
                <v-text-field
                  filled
                  rounded
                  label="Postleitzahl"
                  :rules="[rules.required, rules.plz]"
                  v-model="z_daten.plz"
                ></v-text-field>
              </v-col>
              <v-col cols="11" md="6" class="py-0">
                <v-text-field
                  filled
                  rounded
                  label="Ort"
                  :rules="[rules.required, rules.name]"
                  v-model="z_daten.ort"
                ></v-text-field>
              </v-col>
              <v-col
                cols="11"
                md="10"
                class="py-0"
                v-if="template.var.nationen"
              >
                <v-select
                  filled
                  rounded
                  label="Nation"
                  :rules="[rules.required]"
                  :items="template.var.nationen"
                  v-model="z_daten.nation"
                  @change="change_nation()"
                ></v-select>
              </v-col>
              <v-col cols="11" md="10" class="py-0">
                <v-text-field
                  filled
                  rounded
                  label="Telefon"
                  :rules="[rules.required, rules.telefon]"
                  v-model="z_daten.telefon"
                ></v-text-field>
              </v-col>
              <v-col cols="11" class="py-0 text-center mt-5">
                <v-btn
                  :color="template.colors.primary"
                  :style="`color: ${template.colors.inline_primary_text};`"
                  class="my-5"
                  :block="$vuetify.breakpoint.smAndDown"
                  large
                  rounded
                  @click="save()"
                  :loading="loading"
                >
                  Speichern
                </v-btn>
              </v-col>
            </v-row>
          </v-form>

          <v-row justify="center" class="mt-4" v-if="!publicdata.verein">
            <v-col
              cols="12"
              class="text-center mb-4"
              v-if="publicdata.nodata == false"
            >
              <h2>Verein wird geladen</h2>
              <v-progress-circular
                :color="template.colors.primary"
                circle
                indeterminate
              ></v-progress-circular>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import store from '../../store'
import router from '../../routes/index'
//import firebase from '../../firebaseConfig'

export default {
  name: 'Profil-vervollstaendigen',
  data() {
    return {
      newzuschauer: false,
      z_daten: {
        vorname: '',
        name: '',
        adresse: '',
        plz: '',
        ort: '',
        telefon: '',
        nation: 'Deutschland',
      },
      valid: false,
      loading: false,
      rules: {
        required: (value) => !!value || 'Bitte gebe diese Daten ein',
        name: (v) =>
          v.length >= 2 ? true : 'Bitte gebe einen gültigen Namen ein',
        password: (v) =>
          v.length >= 6
            ? true
            : 'Dein Passwort sollte mindestens 6 Zeichen lang sein',
        passwordconfirm: (v) =>
          v == this.v.password ? true : 'Die Passwörter stimmen nicht überein',
        street: (v) =>
          v.length >= 5 ? true : 'Bitte gebe einen gültigen Straßennamen ein',
        streetnr: (v) =>
          v.match(/[0-9]{1,}/)
            ? true
            : 'Bitte gebe eine gültige Hausnummer ein',
        telefon: (v) =>
          v.match(/[0-9]{5,}/)
            ? true
            : 'Bitte gebe eine gültige Telefonnummer ein',
        plz: (v) =>
          v.match(/[0-9]{5}/)
            ? true
            : 'Bitte gebe eine gültige Postleitzahl ein',
        email: (v) =>
          v.match(
            /^((?!\.)[\w-_.]*[^.])(@\w+)((?!\.)[\w-_.]*[^.])(\.\w+(\.\w+)?[^.\W])$/,
          )
            ? true
            : 'Bitte gebe eine gültige E-Mail Adresse ein',
      },
    }
  },
  computed: {
    ...mapGetters({
      publicdata: 'publicdata',
      user: 'user',
      template: 'template',
    }),
  },
  mounted() {
    firebase.auth().onAuthStateChanged((user) => {
      if (!user) {
        router.push('/' + this.$route.params.verein)
      }
    })
    if (this.$route.params.verein) {
      if (!this.publicdata.verein) {
        store.dispatch('setPublic', {
          name: this.$route.params.verein,
          sportstaette: this.$route.params.sportstaette,
        })
      } else {
        if (this.publicdata.verein.alias != this.$route.params.verein) {
          store.dispatch('setPublic', {
            name: this.$route.params.verein,
            sportstaette: this.$route.params.sportstaette,
          })
        }
      }

      if (this.user) {
        if (this.user.zuschauerdaten) {
          if (this.$route.params.sportstaette) {
            router.push(
              '/' +
                this.$route.params.verein +
                '/' +
                this.$route.params.sportstaette +
                '/erfassen',
            )
          } else {
            router.push('/' + this.$route.params.verein + '/erfassen')
          }
        }
      } else {
        if (this.$route.params.sportstaette) {
          router.push(
            '/' +
              this.$route.params.verein +
              '/' +
              this.$route.params.sportstaette,
          )
        } else {
          router.push('/' + this.$route.params.verein)
        }
      }
    }
  },
  methods: {
    change_nation() {
      switch (this.z_daten.nation) {
        case 'Deutschland':
          this.rules.plz = (v) =>
            v.match(/[0-9]{5}/)
              ? true
              : 'Bitte gebe eine gültige Postleitzahl ein'
          break
        case 'Belgien':
          this.rules.plz = (v) =>
            v.match(/[0-9]{4}/)
              ? true
              : 'Bitte gebe eine gültige Postleitzahl ein'
          break
        case 'Niederlande':
          this.rules.plz = (v) =>
            v.match(/[0-9]{4}/) && v.match(/[a-zA-Z]{2}/)
              ? true
              : 'Bitte gebe eine gültige Postleitzahl ein'
          break
        default:
          this.rules.plz = (v) =>
            v.match(/[0-9]{5}/)
              ? true
              : 'Bitte gebe eine gültige Postleitzahl ein'
          break
      }
    },
    save() {
      if (this.$refs.form.validate()) {
        if (this.user.data.vorname) {
          firebase
            .firestore()
            .collection('User')
            .doc(this.user.uid)
            .collection('Kontaktdaten')
            .doc(this.user.uid)
            .set({
              vorname: this.z_daten.vorname.trim(),
              name: this.z_daten.name.trim(),
              adresse: this.z_daten.adresse.trim(),
              plz: this.z_daten.plz.trim(),
              ort: this.z_daten.ort.trim(),
              telefon: this.z_daten.telefon.trim(),
              nation: this.z_daten.nation.trim(),
            })
            .then(() => {
              router.push('/' + this.$route.params.verein + '/erfassen')
            })
        } else {
          var user = firebase.auth().currentUser
          user.updateProfile({
            displayName: this.z_daten.vorname + ' ' + this.z_daten.name,
          })
          firebase
            .firestore()
            .collection('User')
            .doc(this.user.uid)
            .set({
              vorname: this.z_daten.vorname.trim(),
              nachname: this.z_daten.name.trim(),
              verband: this.template.var.verband,
              rolle: 'Zuschauer',
            })
            .then(() => {
              firebase
                .firestore()
                .collection('User')
                .doc(this.user.uid)
                .collection('Kontaktdaten')
                .doc(this.user.uid)
                .set({
                  vorname: this.z_daten.vorname.trim(),
                  name: this.z_daten.name.trim(),
                  adresse: this.z_daten.adresse.trim(),
                  plz: this.z_daten.plz.trim(),
                  ort: this.z_daten.ort.trim(),
                  telefon: this.z_daten.telefon.trim(),
                  nation: this.z_daten.nation.trim(),
                })
                .then(() => {
                  firebase
                    .firestore()
                    .collection('email')
                    .add({
                      appname: this.template.var.verband,
                      template: 'ZUSCHAUER_SIGN_UP',
                      vorname: this.z_daten.vorname.trim(),
                      to: this.user.email,
                      name:
                        this.z_daten.vorname.trim() +
                        ' ' +
                        this.z_daten.name.trim(),
                    })
                  if (this.$route.params.verein) {
                    router.push('/' + this.$route.params.verein + '/erfassen')
                  } else {
                    router.push('/')
                  }
                })
            })
        }
      }
    },
  },
}
</script>
>
